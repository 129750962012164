<template>
  <CContainer>
    <CRow class="justify-content-center">
      <CCol md="12" class="text-start">
        <div class="d-flex align-items-center">
          <h2>Appointment Pass</h2>
          <div class="d-flex align-items-center ms-4">
            <span class="fw-bold me-2">Off</span>
            <CFormSwitch
              data-test-id="app-module-switch"
              class="modules-switch mx-1"
              shape="pill"
              :checked="isAppModuleActive"
              @update:model-value="toggleStatus(isAppModuleActive)"
            />
            <span class="fw-bold ms-2">On</span>
          </div>
          <HelpCenterButton
            classes="ms-2"
            content-key="modules_appointment_pass_and_request"
          />
        </div>
        <div v-if="isAppModuleActive">
          Please select the Appointment Pass notification method(s) you would
          like to activate for your school. Adults and students will receive
          morning agenda emails if the appointment in which they are involved
          were created at least one day in advance.
          <div class="d-flex mt-4">
            <CFormCheck
              label="Email Alert Notification - Students will receive email alert notifications 20 mins and 5 mins prior to the appointment, in addition to the in application reminders at the same time intervals."
              v-model="form.emailNotification"
              class="custom-checkbox text-start"
              :custom="true"
              @update:model-value="submit"
            />
          </div>
          <div class="d-flex">
            <CFormCheck
              label="Turn off Admins ability to edit other user's Appointment Passes"
              v-model="form.adminEdit"
              class="custom-checkbox text-start"
              :custom="true"
              @update:model-value="submit"
            />
          </div>
          <h3 class="mt-4">Student Appointment Request</h3>
          <p class="mt-4">
            Allow Students to request Appointments to any or all of the
            following. By making a selection here the Student Appointment Pass
            Functionality will be activated for your school.
          </p>
          <div class="d-flex">
            <div class="d-flex align-items-center">
              <CFormCheck
                data-test-class="app-module-checkbox"
                label="Teachers"
                v-model="form.teachers"
                class="custom-checkbox text-start"
                :custom="true"
                @update:model-value="submit"
              />
            </div>
            <div class="d-flex align-items-center ms-4">
              <CFormCheck
                data-test-class="app-module-checkbox"
                label="Admins"
                v-model="form.admins"
                class="custom-checkbox text-start"
                :custom="true"
                @update:model-value="submit"
              />
            </div>
            <div class="d-flex align-items-center ms-4">
              <CFormCheck
                data-test-class="app-module-checkbox"
                label="Staff"
                v-model="form.staff"
                class="custom-checkbox text-start"
                :custom="true"
                @update:model-value="submit"
              />
            </div>
            <div class="d-flex align-items-center ms-4">
              <CFormCheck
                data-test-class="app-module-checkbox"
                label="Locations"
                v-model="form.locations"
                class="custom-checkbox text-start"
                :custom="true"
                @update:model-value="submit"
              />
            </div>
          </div>
          <CAlert
            v-if="reqResponse.message"
            class="mt-4"
            :color="reqResponse.type"
            :show="!!reqResponse.message"
          >
            {{ reqResponse.message }}
          </CAlert>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import HelpCenterButton from "../HelpCenterButton"
import { mapState } from "vuex"
import _ from "underscore"

export default {
  name: "AppointmentPassModuleForm",
  components: { HelpCenterButton },
  data() {
    return {
      isLoading: false,
      form: {
        emailNotification: false,
        adminEdit: false,
        teachers: false,
        admins: false,
        staff: false,
        locations: false
      },
      isAppModuleActive: false,
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      }
    }
  },
  computed: {
    ...mapState({
      options: (state) => {
        const index = _.findIndex(state.schools.activeSchoolModules, {
          name: "Appointmentpass"
        })
        return index !== -1
          ? JSON.parse(
              state.schools.activeSchoolModules[index].pivot.option_json
            )
          : false
      },
      active: (state) => {
        const index = _.findIndex(state.schools.activeSchoolModules, {
          name: "Appointmentpass"
        })
        return index !== -1
          ? Boolean(state.schools.activeSchoolModules[index].pivot.status)
          : false
      }
    })
  },
  mounted() {
    if (this.options) {
      this.form.emailNotification = !!this.options.email
      this.form.adminEdit = !!this.options.admin_edit
      this.form.teachers = !!this.options.teacher
      this.form.admins = !!this.options.admin
      this.form.staff = !!this.options.staff
      this.form.locations = !!this.options.location
    }
    this.isAppModuleActive = this.active
  },
  methods: {
    submit() {
      this.resetResponseMessages()
      const inputsData = JSON.stringify({
        peer: null,
        sms: null,
        email: this.form.emailNotification,
        admin: this.form.admins,
        staff: this.form.staff,
        location: this.form.locations,
        teacher: this.form.teachers,
        admin_edit: this.form.adminEdit
      })
      this.isLoading = true
      this.$store
        .dispatch("modules/updateAppointmentPassModule", {
          option_json: inputsData
        })
        .then(() => {
          this.setSuccessResponse("Module updated.")
          this.$store.dispatch("schools/getActiveModules", {
            clearCacheEntry: true
          })
          this.isLoading = false
          setTimeout(() => {
            this.resetResponseMessages()
          }, 850)
        })
        .catch((err) => {
          const response = err.response.data
          this.setErrorResponse(response.message, response.errors)
          this.isLoading = false
        })
    },
    toggleStatus(status) {
      const moduleStatus = status
        ? {
            status: 0
          }
        : {
            status: 1
          }
      this.$store
        .dispatch("modules/toggleModule", { id: 1, moduleStatus })
        .then(() => {
          this.isAppModuleActive = !this.isAppModuleActive
          this.$store.dispatch("schools/getActiveModules", {
            clearCacheEntry: true
          })
        })
    },
    resetResponseMessages() {
      this.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    },
    setSuccessResponse(message) {
      this.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    },
    setErrorResponse(message, errors) {
      this.reqResponse = {
        type: "danger",
        message: message ? message : "Someting went wrong!",
        errors: errors ? errors : {}
      }
    }
  }
}
</script>
