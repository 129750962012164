<template>
  <CContainer>
    <CRow class="justify-content-center mt-4">
      <CCol md="12" class="text-start">
        <CAlert
          v-if="reqResponse.message"
          class="mt-4"
          :color="reqResponse.type"
          :show="!!reqResponse.message"
        >
          {{ reqResponse.message }}
        </CAlert>
        <div class="d-flex align-items-center">
          <h2>Auto Check-In</h2>
          <div class="d-flex align-items-center ms-4">
            <span class="fw-bold me-2">Off</span>
            <CFormSwitch
              data-test-id="auto-checkin-switch"
              class="modules-switch mx-1"
              shape="pill"
              :checked="isActive"
              @update:model-value="toggleAutoCheckIn(isActive)"
            />
            <span class="fw-bold ms-2">On</span>
          </div>
          <HelpCenterButton classes="ms-2" content-key="module_autocheck_in" />
        </div>
        <p class="mt-4">
          Auto Check-In enables assigning a public PIN to a room so that
          students can Check into room using that public PIN
        </p>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { mapState } from "vuex"
import HelpCenterButton from "../HelpCenterButton"
import _ from "underscore"

export default {
  name: "AutoCheckInModuleForm",
  components: { HelpCenterButton },
  data() {
    return {
      isLoading: false,
      form: {},
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      },
      isActive: false
    }
  },
  computed: {
    ...mapState({
      options: (state) => {
        const index = _.findIndex(state.schools.activeSchoolModules, {
          name: "Auto Check-In"
        })
        return index !== -1
          ? JSON.parse(
              state.schools.activeSchoolModules[index].pivot.option_json
            )
          : false
      },
      active: (state) => {
        const index = _.findIndex(state.schools.activeSchoolModules, {
          name: "Auto Check-In"
        })
        return index !== -1
          ? Boolean(state.schools.activeSchoolModules[index].pivot.status)
          : false
      }
    })
  },
  mounted() {
    this.isActive = this.active
  },
  methods: {
    toggleAutoCheckIn(status) {
      const moduleStatus = status
        ? {
            status: 0
          }
        : {
            status: 1
          }
      this.$store
        .dispatch("modules/toggleModule", { id: 2, moduleStatus })
        .then(() => {
          this.isActive = !this.isActive
          this.$store.dispatch("schools/getActiveModules", {
            clearCacheEntry: true
          })
        })
    },
    resetResponseMessages() {
      this.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    },
    setSuccessResponse(message) {
      this.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    },
    setErrorResponse(message, errors) {
      this.reqResponse = {
        type: "danger",
        message: message ? message : "Someting went wrong!",
        errors: errors ? errors : {}
      }
    }
  }
}
</script>
